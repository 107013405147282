<div
  [ngStyle]="{
    'background-image': 'url(/assets/hints/' + hint.images[0] + ')'
  }"
>
  <div>
    <spacer></spacer>

    <mat-card>
      <mat-card-header *ngIf="hint.hint !== undefined">
        <div mat-card-avatar></div>
        <mat-card-title>Dit is de tip voor je volgende locatie</mat-card-title>
        <mat-card-title>{{ hint.hint }}</mat-card-title>
      </mat-card-header>

      <mat-card-content *ngIf="hint.images.length > 0">
        <img *ngFor="let img of hint.images" [src]="'/assets/hints/' + img" />
      </mat-card-content>

      <mat-card-actions>
        <button
          mat-raised-button
          [disabled]="!(isClose$ | async)"
          color="warn"
          (click)="next()"
        >
          <span>Ik ben er! Volgende tip!</span>
        </button>
        <spacer></spacer>
        <button mat-icon-button id="help" color="primary" (click)="openHelp()">
          <mat-icon>help_outline</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <section *ngIf="admin">
      <button mat-raised-button (click)="next()">Volgende</button>
    </section>

    <spacer></spacer>
  </div>
</div>
