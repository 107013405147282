import { Component, OnInit } from '@angular/core';
import hints from './shared/hints';
import { LocationHint } from './shared/location-hint';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  state: string;
  locationHintTree: LocationHint;

  constructor() {}

  ngOnInit() {
    this.state = 'intro';
    this.locationHintTree = hints.reduceRight((prev, curr) => {
      curr.nextLocationHint = prev;
      return curr;
    });
  }

  startSearch() {
    this.state = 'busy';
  }

  getNextLocationHint() {
    if (this.locationHintTree.nextLocationHint === undefined) {
      this.state = 'outro';
    } else {
      this.locationHintTree = this.locationHintTree.nextLocationHint;
    }
  }
}
