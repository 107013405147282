import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent {
  constructor(public dialogRef: MatDialogRef<DisclaimerComponent>) {}

  start() {
    this.dialogRef.close(true);
  }
}
