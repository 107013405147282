import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PositionService } from '../shared/position.service';

export class HelpData {
  from: [number, number];
  to: [number, number];
  distance: number;
}

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  constructor(
    private positionService: PositionService,
    public dialogRef: MatDialogRef<HelpComponent>
  ) {}

  get distanceToTarget$() {
    return this.positionService.distanceToTarget$;
  }

  generateMapsLink() {
    return `https://www.google.com/maps/place/${this.positionService.target[0]},${this.positionService.target[1]}`;
  }

  admin() {
    if (prompt('Admin code') === '1121') {
      this.dialogRef.close(true);
    } else {
      alert('Nice try');
    }
  }
}
