<mat-card>
  <mat-card-header>
    <mat-card-title
      ><h1>
        Beste familie,<br />
        welkom bij de <b>interactieve nieuwjaarswandeling 2022</b>
      </h1>
    </mat-card-title>
  </mat-card-header>

  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="start()">Klaar?</button>
  </mat-card-actions>
</mat-card>
