import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HelpComponent } from '../help/help.component';
import { LocationHint } from '../shared/location-hint';
import { PositionService } from '../shared/position.service';
@Component({
  selector: 'location-hint',
  templateUrl: './location-hint.component.html',
  styleUrls: ['./location-hint.component.scss'],
})
export class LocationHintComponent {
  admin: boolean;

  _hint: LocationHint;
  @Input()
  set hint(hint: LocationHint) {
    this._hint = hint;
    this.positionService.target = hint.coordinates;
  }
  get hint() {
    return this._hint;
  }
  @Output() success: EventEmitter<boolean>;

  @HostListener('window:beforeunload', ['$event'])
  preventExit($event) {
    $event.returnValue =
      'Ben je zeker dat je de app wil verlaten? Je wandeling voortgang zal verloren gaan!';
  }

  isClose$: Observable<boolean>;

  constructor(
    private positionService: PositionService,
    private dialog: MatDialog
  ) {
    this.admin = false;
    this.success = new EventEmitter<boolean>();
    this.isClose$ = positionService.distanceToTarget$.pipe(
      map((distance) => Number.isInteger(distance) && distance < 70),
      startWith(false)
    );
  }

  next() {
    this.success.emit(true);
  }

  openHelp() {
    this.dialog
      .open(HelpComponent)
      .afterClosed()
      .subscribe((result) => (this.admin = !!result));
  }
}
