<mat-card>
  <mat-card-header>
    <mat-card-title
      ><h1>
        Je bent er!
        <br />
        <br />
        Geniet van een traktatie op de eindbestemming, en een gelukkig
        nieuwjaar!
      </h1>
    </mat-card-title>
    <mat-card-subtitle>Op naar een leuk 2022!</mat-card-subtitle>
  </mat-card-header>
</mat-card>
