import { Injectable } from '@angular/core';
import * as haversine from 'haversine';
import { ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

export interface GeolocationPosition {
  coords: GeolocationCoordinates;
  timestamp: number;
}

export interface GeolocationCoordinates {
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  currentPosition$ = new ReplaySubject<GeolocationPosition>(1);
  distanceToTarget$ = new ReplaySubject<number>(1);

  private _target: [number, number] | null;
  set target(target: [number, number] | null) {
    this._target = target;
    this.currentPosition$
      .pipe(take(1), this.mapCurrentPositionToDistance())
      .subscribe((distance) => this.distanceToTarget$.next(distance));
  }
  get target() {
    return this._target;
  }

  constructor() {
    navigator.geolocation.watchPosition((pos) =>
      this.currentPosition$.next(pos)
    );

    this.currentPosition$
      .pipe(this.mapCurrentPositionToDistance())
      .subscribe((distance) => this.distanceToTarget$.next(distance));
  }

  private mapCurrentPositionToDistance() {
    return map(({ coords: { latitude, longitude } }) =>
      this.getDistanceInMeters([latitude, longitude], this.target)
    );
  }

  private getDistanceInMeters(
    from: [number, number],
    to: [number, number] | null
  ) {
    return to === null
      ? 0
      : Math.floor(
          haversine(from, to, {
            unit: 'meter',
            format: '[lat,lon]',
          })
        );
  }
}
