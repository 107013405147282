<mat-dialog-content>
  <section class="mat-typography">
    <h2>Beste familie</h2>
    <p class="mat-caption">Enkele tips voor een vlot verloop.</p>
    <p>
      Je kan geen vorige tips gekijken. Dat betekent dat de
      <span class="mat-body-strong">terug</span> toets
      <span class="mat-body-strong">de app sluit!</span> Je krijgt wel een
      waarschuwing maar als je alsnog op "Verlaten" of nog eens op terug drukt
      zal je de app afsluiten waarbij je
      <span class="mat-body-strong">vooruitgang verloren gaat.</span>
    </p>
    <p>
      Sommige tips hebben geen locatie, deze kan je direct wegklikken,
      <span class="mat-body-strong">vergeet niet eerst te kijken 😉</span>
    </p>
    <p>
      Vergeet niet
      <span class="mat-body-strong"
        >op volgende tip te klikken vooraleer je verder wandelt,</span
      >
      anders zal je terug moeten wandelen naar het vorige punt om verder te
      kunnen met de volgende tip.
    </p>
  </section>
  <button mat-raised-button color="warn" (click)="start()">Begrepen!</button>
</mat-dialog-content>
