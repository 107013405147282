export class LocationHint {
  hint: string;
  images: Array<string>;
  coordinates: [number, number] | null;
  nextLocationHint: LocationHint;

  constructor() {
    this.images = new Array();
  }
}

export class LocationHintBuilder {
  private locationHint: LocationHint;

  constructor() {
    this.locationHint = new LocationHint();
  }

  withHint(hint: string): LocationHintBuilder {
    this.locationHint.hint = hint;
    return this;
  }

  withImages(images: Array<string>): LocationHintBuilder {
    this.locationHint.images = images;
    return this;
  }

  withLocation(location: [number, number]): LocationHintBuilder {
    this.locationHint.coordinates = location;
    return this;
  }

  withNextLocation(nextLocation: LocationHint): LocationHintBuilder {
    this.locationHint.nextLocationHint = nextLocation;
    return this;
  }

  build(): LocationHint {
    // if (this.locationHint.images.length === 0) {
    //   this.locationHint.images.push('location.jpg');
    // }
    if (!this.locationHint.coordinates) {
      this.locationHint.coordinates = null;
    }
    return this.locationHint;
  }
}
