import { LocationHintBuilder as LHB } from './location-hint';

export default [
  new LHB()
    .withHint(
      'Hier gaan we weer! Voor hen die vorig jaar de wandeling deden, we beginnen op dezelfde plaats 😉. Voor de rest, veel succes met het vinden van de startplaats!'
    )
    .withImages(['vijver.jpg'])
    .withLocation([51.102295, 5.146256])
    .build(),
  new LHB()
    .withHint('Follow the leader (Trixie é)')
    .withImages(['gracht.jpg'])
    .build(),
  new LHB()
    .withImages(['kapel.jpg'])
    .withLocation([51.10461, 5.146314])
    .build(),
  new LHB()
    .withHint('Het is maar een KORT stukske')
    .withImages(['kerstboom.jpg'])
    .withLocation([51.105614, 5.152639])
    .build(),
  new LHB()
    .withImages(['wegwijzer.jpg'])
    .withLocation([51.104218, 5.154566])
    .build(),
  new LHB()
    .withHint('Flink gewandeld al, je hebt een borrelpauze verdiend!')
    .withImages(['kerstboom-binnen.jpg'])
    .withLocation([51.104084, 5.159714])
    .build(),
  new LHB()
    .withImages(['marchetta.jpg'])
    .withLocation([51.103341, 5.160112])
    .build(),
  new LHB()
    .withHint('Geniet van dit bankje in het bos!')
    .withImages(['bankje.jpg'])
    .withLocation([51.10112, 5.160627])
    .build(),
  new LHB()
    .withImages(['fietspad.jpg'])
    .withLocation([51.097524, 5.155305])
    .build(),
  new LHB()
    .withImages(['kaart.jpg'])
    .withLocation([51.098728, 5.153337])
    .build(),
  new LHB().withImages(['hek.jpg']).withLocation([51.098976, 5.151268]).build(),
  new LHB()
    .withImages(['vuilbak.jpg'])
    .withLocation([51.099922, 5.14969])
    .build(),
  new LHB()
    .withHint('De Rozehoppers')
    .withImages(['trixiebank.jpg'])
    .withLocation([51.099312, 5.14692])
    .build(),
  new LHB()
    .withImages(['riverwood.jpg'])
    .withLocation([51.099403, 5.144036])
    .build(),
  new LHB()
    .withImages(['benvenuti.jpg'])
    .withLocation([51.098904, 5.141854])
    .build(),
];
