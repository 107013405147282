<button mat-icon-button mat-dialog-close id="close">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
  <section class="mat-typography">
    <h2>Help!</h2>
    <span>
      Je zit op <span class="bold">{{ distanceToTarget$ | async }}m</span> van
      het doel!
    </span>
    <br />
    <span>
      Denk je dat dit niet klopt? Open dan af en toe even Google Maps of een
      andere GPS applicatie om je GPS nauwkeuriger te laten meten.
    </span>
    <br />
    <span>
      Zorg dat je GPS aan staat en dat deze site toegang heeft tot je locatie.
    </span>
    <br />
    <span>
      Hulp nodig? <a class="tel" href="tel:0475584275">0475 / 58 42 75</a>
    </span>
    <br />
    <span
      >Weet je echt niet waar het is? Typ dan hier "Ik geef veel te snel op" en
      klik op de onderstaande knop.</span
    >
    <mat-form-field>
      <mat-label>Ik geef veel te snel op</mat-label>
      <input matInput #giveUp />
    </mat-form-field>
    <a
      mat-raised-button
      [disabled]="giveUp.value.toLowerCase() !== 'ik geef veel te snel op'"
      color="warn"
      [href]="generateMapsLink()"
      target="_blank"
      >Klik hier voor de locatie</a
    >
  </section>

  <section>
    <button mat-button (click)="admin()">Admin</button>
  </section>
</mat-dialog-content>
