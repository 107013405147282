import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerComponent } from '../disclaimer/disclaimer.component';

@Component({
  selector: 'intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent {
  @Output() begin: EventEmitter<boolean>;

  constructor(private dialog: MatDialog) {
    this.begin = new EventEmitter<boolean>();
  }

  start() {
    this.dialog
      .open(DisclaimerComponent)
      .afterClosed()
      .subscribe((res) => res === true && this.begin.emit(res));
  }
}
